import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import './QRScanner.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import closeBtn from '../assets/images/close.png'

const QrScanner = ({walletAddress}) => {
  const [code, setCode] = useState(null);
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const handleScan = (scanData) => {
    if (scanData && scanData) {
      walletAddress(scanData)
      setCode(scanData);
    }
  };

    const handleNavigate = () => {
    navigate("/");
  };

  return (
    <>
      <div className="scanner-holder">
        <button className="qr-close-btn" onClick={handleNavigate}>
          <img src={closeBtn} />
        </button>
        {!showDialog && !processing && (
          <QrReader
            delay={500}
            onScan={handleScan}
            constraints={{
              facingMode: "environment",
            }}
            className="qr-reader"
          />
        )}
      </div>
    </>
  );
};

export default QrScanner;
