
import Home from "../views/Home";
import Wallet from "../views/Wallet";


const routes = [
  {
    path: "/",
    component: <Home/>,
    exact: true,
  },
  {
    path: "/wallet",
    component: <Wallet/>,
    exact: true,
  },
];

export default routes;
