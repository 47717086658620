import React, { useEffect, useState } from 'react';
import { LobkowitzWallet } from 'lobkowitz-wallet-component';
import QrScanner from '../components/QRScanner';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

// const walletAddress = '0xedc21400DafD1CBa357cBe99d56197c20Da51d37';

const Wallet = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [isAddressChecked, setAddressChecked] = useState(false)
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search).get('address');
    if(urlParams !== "null" && urlParams){
      setWalletAddress(urlParams)
      
    }
    setAddressChecked(true)
  }, [])

  const goBack = () => {
    navigate("/");
  }
  
  return (
    <>
    {isAddressChecked &&
    <>
    {
      !walletAddress ? <QrScanner walletAddress={(add)=>{setWalletAddress(add)}}/> : <LobkowitzWallet address={walletAddress} navigateBack={goBack} isUser errorNavigateBack={goBack}  contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS} rpc_url={process.env.REACT_APP_RPC_URL }/>
    }
      
    
      {/*  */}
    </>}</>
  );
};

export default Wallet;
