import "./Home.scss";
import logo from "../assets/images/lobkowitz-crest.png"
import { useNavigate } from "react-router-dom";
import { useState } from "react";
const Home = () =>{
    const [manualAddressInput, setManualAddressInput] = useState(false);
    const [address,setAddress] = useState(null);
    const [addressError,setAddressError] =  useState(null);
    const navigate = useNavigate();
    const handleNavigatToQRScan = () =>{
        navigate("/wallet")
    }
    const handleManualAddress = () =>{
        setManualAddressInput(true)
    }
    const showNFTs = () =>{
        let first2;
        if(address !== null){
            first2 = address.slice(0, 2);
        }
        if(first2 === "0x" && address.length > 25){
            navigate("/wallet?address=" +  address)
        }else{
            setAddressError("Invalid address")
        }
    }
    const handleInputChange = (event) =>{
        const address = event.target.value
        setAddress(address)   
    }
    return <>
    <div className="lb-home">
        <div className="header-section">
            <img src={logo}/>
        </div>
        <div className="lb-content-section">
        {!manualAddressInput && <h2 className="page-title" style={{color:"#fff"}}> 
                Welcome to <br/> NFC 2022
            </h2>}
            {!manualAddressInput ?
            <p >Scan the QR code on your card <br/> or enter your address manually<br/> to review your NFTs</p> :
            <>
            <p >Enter your address manually to <br/> review your NFTs</p>
            <input className="address-input" onChange={handleInputChange} type="text"/>
            {addressError && <p>{addressError}</p>}
            </>
}   
        </div>
        <div className="lb-footer">
        {!manualAddressInput ? <>
            <div className="main-button" onClick={handleNavigatToQRScan}>Scan QR Code</div>
            <div className="main-button" onClick={handleManualAddress}>Enter manually</div>
            </> :  <div className="main-button" onClick={showNFTs}>Show NFTS</div>
        }
        </div>
    </div>
    </>
}

export default Home;