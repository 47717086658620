import './App.css';
import React from 'react';
import routes from './routes/Routes';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { HashRouter, Redirect } from 'react-router-dom';

function App() {
  return (
    <div className="App">
       <HashRouter basename='/'>
            <Routes>
              {routes.map(({ path, component, exact }) => (
                <Route key={path} path={path} element={component} exact={exact} />
              ))}
            </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
